import React, { forwardRef } from 'react';

import { List } from 'antd';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  InfoCircleFilled,
  WarningFilled,
} from '@ant-design/icons';
import PropTypes from 'prop-types';

import NotificationsListItemLoadMore from './NotificationsListItemLoadMore';
import NotificationsListItemTitle from './NotificationsListItemTitle';
import NotificationsListItemContent from './NotificationsListItemContent';

import useNotificationListItem from './hooks/useNotificationListItem';

const NotificationsListItem = forwardRef(({
  id,
  message,
  description,
  labelType,
  action,
  createdAt,
  isRead,
  canLoadMore,
  isLoadingMore,
  handleLoadMore,
}, ref) => {
  const {
    isUpdating,
    handlePerformAction,
  } = useNotificationListItem({ id });

  const notificationIcons = {
    info: <InfoCircleFilled style={{ fontSize: 25, color: '#0071CD' }} />,
    success: <CheckCircleFilled style={{ fontSize: 25, color: '#14803C' }} />,
    warning: <WarningFilled style={{ fontSize: 25, color: '#9A6412' }} />,
    error: <CloseCircleFilled style={{ fontSize: 25, color: '#CF3537' }} />,
  };

  const notificationBackgrounds = {
    info: '#EAF6FF',
    success: '#DFFBE8',
    warning: '#FFF3DD',
    error: '#FFF1F1',
  };

  return (
    <List.Item
      className="top-menu-instant-notifications__list-item"
      key={id}
      ref={ref}
      style={{ backgroundColor: (isRead ? 'unset' : notificationBackgrounds[labelType]) }}
    >
      {(id === 'load-more' && canLoadMore) ? (
        <NotificationsListItemLoadMore
          isLoadingMore={isLoadingMore}
          handleLoadMore={handleLoadMore}
        />
      ) : (
        <List.Item.Meta
          id={id}
          avatar={notificationIcons[labelType]}
          title={(
            <NotificationsListItemTitle
              message={message}
              createdAt={createdAt}
            />
          )}
          description={(
            <NotificationsListItemContent
              description={description}
              action={action}
              handlePerformAction={handlePerformAction}
              isUpdating={isUpdating}
            />
          )}
        />
      )}
    </List.Item>
  );
});

NotificationsListItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  message: PropTypes.string,
  description: PropTypes.string,
  labelType: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
  action: PropTypes.shape({
    url: PropTypes.string.isRequired,
    text: PropTypes.string,
  }),
  createdAt: PropTypes.string,
  isRead: PropTypes.bool,
  canLoadMore: PropTypes.bool.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
  handleLoadMore: PropTypes.func.isRequired,
};

NotificationsListItem.defaultProps = {
  description: '',
  action: null,
  message: '',
  labelType: 'info',
  isRead: false,
  createdAt: null,
};

export default NotificationsListItem;
