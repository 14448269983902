/* global window */
import {
  timeZoneName,
  isTimecopFrozen,
  currentTimeUnixMilliseconds,
} from 'helpers/userSessionSettings';

// Import fonts
import 'vendor/fonts/notosans/notosans-vf.scss';
import 'vendor/fonts/crops-icons/crops-icons.scss';

// C3 styles
import 'c3/c3.css';

// maplibre-gl styles
import 'maplibre-gl/dist/maplibre-gl.css';
import '@cropio-js/mapbox-gl-draw/dist/mapbox-gl-draw.css';

// CSS styles for spin.js
import 'spin.js/spin.css';

// Import required to `expose-loader` plugin for jQuery.
import 'jquery'; // eslint-disable-line no-restricted-imports

// jquery-ui/ui/widgets/datepicker
// https://jqueryui.com/datepicker/
// $(...).datepicker();
// FIXME: Used only in several places, should be removed sometime.
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/datepicker.css';
import 'jquery-ui/themes/base/theme.css';

// fancybox plugin
// https://github.com/fancyapps/fancybox
// <a data-fancybox="gallery" href="big_1.jpg">...</a>
// FIXME: Used only in several places, should be removed sometime.
import '@fancyapps/fancybox';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';

// Bootstrap3 (loaded as jQuery plugin)
import 'bootstrap'; // eslint-disable-line no-restricted-imports

// https://github.com/seiyria/bootstrap-slider
// $(...).slider();
// $(...).bootstrapSlider();
// FIXME: Used only in several places, should be removed sometime.
import 'bootstrap-slider/dist/css/bootstrap-slider.css';

// Popover fix
import 'vendor/bootstrap-popover-fix';

// https://github.com/select2/select2
// $(...).select2()
import 'select2'; // eslint-disable-line no-restricted-imports
import 'select2/select2.css';
// Bootstrap3 style for select2 v3.5
import 'select2-bootstrap-css/select2-bootstrap.css';

import { } from 'jquery-ujs'; // eslint-disable-line no-restricted-imports

import moment from 'moment';
import 'moment-timezone/builds/moment-timezone-with-data-1970-2030';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// https://github.com/dangrossman/daterangepicker
// $(...).daterangepicker()
// FIXME: Used only in several places, should be removed sometime.
import 'bootstrap-daterangepicker/daterangepicker.css';
// bootstrap-daterangepicker style redefine
import 'vendor/bootstrap-daterangepicker/style.scss';

// WeatherIcons font and styles
import 'weathericons/css/weather-icons.css';
import 'weathericons/css/weather-icons-wind.css';

// FontAwesome
import '@fortawesome/fontawesome-pro/css/all.css';
import '@fortawesome/fontawesome-pro/css/v4-shims.css';

// react-loading-skeleton
import 'react-loading-skeleton/dist/skeleton.css';

import ReactRailsUJS from 'react_ujs';

// Import styles for common libraries
import 'react-virtualized/styles.css';

// Load Views for side-effects
import 'views';

// Daterangepicker selectors bug in Firefox fix
import 'vendor/selectors-bug-in-firefox-fix';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

// Image Lightbox styles
import 'react-image-lightbox/style.css';
import { createConsumer } from '@rails/actioncable';

dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(weekday);
dayjs.extend(timezone);
dayjs.extend(utc);

// Set default time from global
if (typeof timeZoneName !== 'undefined') {
  moment.tz.setDefault(timeZoneName);
  dayjs.tz.setDefault(timeZoneName);
}

if (isTimecopFrozen) {
  moment.now = () => currentTimeUnixMilliseconds;
  dayjs.now = () => currentTimeUnixMilliseconds;
}

// Support component names relative to this directory:
const componentRequireContext = require.context('components_for_views', false);
ReactRailsUJS.useContext(componentRequireContext); // eslint-disable-line react-hooks/rules-of-hooks

// FIXME: Globals for telematics
/* eslint-disable camelcase */
window.min_speed = 0;
window.max_speed = 60;
window.speed_colorized_enabled = false;
window.stops_colorizing = false;
window.unloading_colorizing = false;
window.stops_time_minutes = 5;
/* eslint-enable camelcase */

try {
  window.consumer = createConsumer();
} catch (e) {
  // eslint-disable-next-line no-console
  console.warn('Unable to connect websocket: ', e);
}
