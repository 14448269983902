import { Button, Row } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';

const NotificationsListItemLoadMore = ({ isLoadingMore, handleLoadMore }) => (
  <Row justify="center" style={{ width: '100%' }}>
    <Button
      loading={isLoadingMore}
      disabled={isLoadingMore}
      onClick={handleLoadMore}
    >
      {I18n.t('components.top_menu_instant_notifications.load_more')}
    </Button>
  </Row>
);

NotificationsListItemLoadMore.propTypes = {
  isLoadingMore: PropTypes.bool.isRequired,
  handleLoadMore: PropTypes.func.isRequired,
};

export default NotificationsListItemLoadMore;
