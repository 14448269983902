import axios from 'axios';
import {
  mark_as_read_instant_notification_path,
  perform_action_instant_notification_path,
} from 'js-routes/generated/routes';

export const markAsRead = (id) => axios({
  url: mark_as_read_instant_notification_path(id),
  method: 'POST',
});

export const performAction = (id) => axios({
  url: perform_action_instant_notification_path(id),
  method: 'POST',
});
