/* global window */

import { useState } from 'react';
import { performAction } from '../../helpers';

const useNotificationListItem = ({ id }) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const handlePerformAction = () => {
    setIsUpdating(true);

    performAction(id).then(({ data: { redirectTo } }) => {
      window.location.href = redirectTo;
    }).finally(() => setIsUpdating(false));
  };

  return {
    isUpdating,
    handlePerformAction,
  };
};

export default useNotificationListItem;
