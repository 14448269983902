/* eslint-disable no-undef */

import onElelementExistRunOnce from 'helpers/jquery/onElementExistRunOnce';

window.addEventListener('load', () => {
  onElelementExistRunOnce('.fertilizers_index_page', () => {
    import('./internal/fertilizers_index_page').then(({ default: Component }) => Component());
  });

  onElelementExistRunOnce('.fertilizers_new_page, .fertilizers_edit_page', () => {
    import('./internal/fertilizers_new_page__fertilizers_edit_page').then(({ default: Component }) => Component());
  });
});
