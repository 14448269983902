import { useEffect, useState } from 'react';
import { instant_notifications_path } from 'js-routes/generated/routes';
import axios from 'axios';

const useNotificationsList = ({
  containerHeight,
  initialNextId,
  setNotifications,
}) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [canLoadMore, setCanLoadMore] = useState(!!initialNextId);
  const [nextId, setNextId] = useState(initialNextId);

  useEffect(() => setCanLoadMore(!!nextId), [nextId]);

  const loadMoreNotifications = () => {
    /* eslint-disable camelcase */
    setIsLoadingMore(true);

    axios({
      url: instant_notifications_path({ begin_from_id: nextId }),
      method: 'GET',
    }).then(({ data: { items, next_id } }) => {
      setNotifications((prevNotifications) => [
        ...prevNotifications.filter((e) => e.id !== 'load-more'),
        ...items,
        ...(next_id ? [{ id: 'load-more', isRead: true }] : []),
      ]);
      setNextId(next_id);
    }).finally(() => setIsLoadingMore(false));
    /* eslint-enable camelcase */
  };

  const handleLoadMore = () => {
    if (isLoadingMore || !canLoadMore) {
      return;
    }

    loadMoreNotifications();
  };

  const handleListScroll = (event) => {
    const { currentTarget } = event;

    if (isLoadingMore || !canLoadMore) {
      return;
    }

    if (currentTarget.scrollHeight - currentTarget.scrollTop === containerHeight) {
      loadMoreNotifications();
    }
  };

  return {
    canLoadMore,
    isLoadingMore,
    handleListScroll,
    handleLoadMore,
  };
};

export default useNotificationsList;
