import { Button, Row } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

const NotificationsListItemContent = ({
  description, action, isUpdating, handlePerformAction,
}) => (
  <>
    <Row>{description}</Row>
    {action?.text && (
    <Row style={{ marginTop: 10 }}>
      <Button
        loading={isUpdating}
        disabled={isUpdating}
        onClick={handlePerformAction}
        style={{ background: 'none' }}
        size="small"
      >
        {action.text}
      </Button>
    </Row>
    )}
  </>
);

NotificationsListItemContent.propTypes = {
  isUpdating: PropTypes.bool.isRequired,
  handlePerformAction: PropTypes.func.isRequired,
  description: PropTypes.string,
  action: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
  }),
};

NotificationsListItemContent.defaultProps = {
  description: '',
  action: {
    text: '',
    url: null,
  },
};

export default NotificationsListItemContent;
