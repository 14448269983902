/* global window */

import React from 'react';

import { Button, Drawer, Result } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';

import NotificationsList from './nested/NotificationsList';

import useTopMenuInstantNotifications from './hooks/useTopMenuInstantNotifications';

const TopMenuInstantNotifications = ({ initialNotifications, initialNextId }) => {
  const {
    isDrawerOpen,
    notifications,
    containerHeight,
    containerRef,
    setNotifications,
    hasUnreadNotifications,
    handleOpenDrawer,
    handleCloseDrawer,
  } = useTopMenuInstantNotifications({ initialNotifications, initialNextId });

  if (!window.consumer) {
    return null;
  }

  const toggleButtonClasses = [
    'top-menu-instant-notifications__toggle-btn',
    ...(hasUnreadNotifications ? ['top-menu-instant-notifications__toggle-btn-unread'] : [])];

  return (
    <div className="top-menu-instant-notifications">
      <Button
        className={toggleButtonClasses.join(' ')}
        onClick={handleOpenDrawer}
        type="link"
        block
      >
        <BellOutlined />
      </Button>

      <Drawer
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
        className="top-menu-instant-notifications__drawer"
        title={I18n.t('components.top_menu_instant_notifications.title')}
        zIndex={1032}
        closable
      >
        <div ref={containerRef} style={{ height: '100%' }}>
          {notifications.length ? (
            <NotificationsList
              notifications={notifications}
              containerHeight={containerHeight}
              setNotifications={setNotifications}
              initialNextId={initialNextId}
            />
          ) : (
            <Result
              status="404"
              title={I18n.t('components.top_menu_instant_notifications.no_notifications')}
            />
          )}
        </div>
      </Drawer>
    </div>
  );
};

TopMenuInstantNotifications.propTypes = {
  initialNotifications: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
    description: PropTypes.string,
    labelType: PropTypes.oneOf(['info', 'success', 'warning', 'error']).isRequired,
    action: PropTypes.shape({
      url: PropTypes.string.isRequired,
      text: PropTypes.string,
    }),
    createdAt: PropTypes.string.isRequired,
    isRead: PropTypes.bool.isRequired,
  })),
  initialNextId: PropTypes.number,
};

TopMenuInstantNotifications.defaultProps = {
  initialNotifications: [],
  initialNextId: null,
};

export default TopMenuInstantNotifications;
