/* global window */

import { useLayoutEffect } from 'react';

const useActionCable = (channelName, {
  onConnect, onDisconnect, onReceive,
}) => {
  useLayoutEffect(() => {
    if (!window.consumer) { return; }

    window.consumer.subscriptions.create({ channel: channelName }, {
      connected: () => onConnect,
      disconnected: () => onDisconnect,
      received: onReceive,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useActionCable;
