import React from 'react';
import PropTypes from 'prop-types';

const NotificationsListItemTitle = ({
  message, createdAt,
}) => (
  <div className="top-menu-instant-notifications__list-item-title">
    <span className="top-menu-instant-notifications__list-item-title__date">{createdAt}</span>
    {message}
  </div>
);

NotificationsListItemTitle.propTypes = {
  message: PropTypes.string,
  createdAt: PropTypes.string,
};

NotificationsListItemTitle.defaultProps = {
  createdAt: '',
  message: '',
};

export default NotificationsListItemTitle;
