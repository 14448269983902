import React from 'react';

import { List } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import VirtualList from 'rc-virtual-list';

import PropTypes from 'prop-types';
import NotificationsListItem from './NotificationsListItem';

import useNotificationsList from './hooks/useNotificationsList';

const NotificationsList = ({
  notifications,
  containerHeight,
  initialNextId,
  setNotifications,
}) => {
  const {
    handleListScroll,
    canLoadMore,
    isLoadingMore,
    handleLoadMore,
  } = useNotificationsList({
    containerHeight,
    initialNextId,
    setNotifications,
  });

  return (
    <List className="top-menu-instant-notifications__list">
      <VirtualList
        data={notifications}
        height={containerHeight}
        itemHeight={47}
        onScroll={handleListScroll}
        itemKey="id"
      >
        {({
          id, message, description, labelType, action, createdAt, isRead,
        }) => (
          <NotificationsListItem
            id={id}
            message={message}
            description={description}
            labelType={labelType}
            action={action}
            createdAt={createdAt}
            isRead={isRead}
            canLoadMore={canLoadMore}
            isLoadingMore={isLoadingMore}
            handleLoadMore={handleLoadMore}
          />
        )}
      </VirtualList>
    </List>
  );
};

NotificationsList.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    message: PropTypes.string,
    description: PropTypes.string,
    labelType: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
    action: PropTypes.shape({
      url: PropTypes.string.isRequired,
      text: PropTypes.string,
    }),
    createdAt: PropTypes.string,
    isRead: PropTypes.bool,
  })),
  containerHeight: PropTypes.number.isRequired,
  initialNextId: PropTypes.number,
  setNotifications: PropTypes.func.isRequired,
};

NotificationsList.defaultProps = {
  notifications: [],
  initialNextId: null,
};

export default NotificationsList;
